import React from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/hero'
import IframeResizer from 'iframe-resizer-react'

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default function DeveloperPortalPage() {
  return (
    <>
      <Hero>
        <h1 className="uk-heading-small uk-text-center">Developer Portal</h1>
        {/* <p className="uk-width-xlarge">
          Interested in how Jasnita can help your company with user experience? Leave you information and a dedicated
          specialist will be in touch!
        </p> */}
      </Hero>

      <section
        className="uk-margin-large"
        // style={{ marginTop: -100 }}
      >
        <div className="uk-container">
          <IframeResizer
            src="https://cpapi.jasnita.co.id/api/docs"
            heightCalculationMethod="documentElementScroll"
            style={{ width: '100%', height: '210vh' }}
          />
        </div>
      </section>
    </>
  )
}
